import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Person from "./Person"

export default function Management() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 27 }) {
        id
        management {
          peopleManagement {
            bornManagement
            holdingManagement
            nameManagement
            otherAssigmentsManagement
            previousExperienceManagement
            titleManagement
            imageManagement {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const persons = data.wpPage.management.peopleManagement

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
      {persons.map((person, i) => {
        return <Person key={i} managementPerson={person} />
      })}
    </div>
  )
}
