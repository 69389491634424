import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Person({ managementPerson }) {
  const {
    bornManagement,
    holdingManagement,
    nameManagement,
    otherAssigmentsManagement,
    previousExperienceManagement,
    titleManagement,
    imageManagement,
  } = managementPerson
  const img = imageManagement?.localFile.childImageSharp
  return (
    <div className="flex flex-col gap-4">
      <div className="grayscale">
        {img ? (
          <GatsbyImage image={getImage(img)} />
        ) : (
          <p>
            <StaticImage src="../../images/blank-profile.webp" />
          </p>
        )}
      </div>
      <div className="flex flex-col gap-2 text-base">
        {nameManagement ? (
          <p>
            <span className="text-primary font-bold inline">Name:</span>{" "}
            <span className="inline">{nameManagement}</span>
          </p>
        ) : null}
        {titleManagement ? (
          <p>
            <span className="text-primary font-bold inline">Title:</span>{" "}
            <span className="inline">{titleManagement}</span>
          </p>
        ) : null}
        {bornManagement ? (
          <p>
            <span className="text-primary font-bold inline">Born:</span>{" "}
            <span className="inline">{bornManagement}</span>
          </p>
        ) : null}

        {previousExperienceManagement ? (
          <div className=" text-gray-700">
            <span className="text-primary font-bold">
              Previous experience:{" "}
            </span>
            <div
              dangerouslySetInnerHTML={{ __html: previousExperienceManagement }}
              className="wp-content"
            />
          </div>
        ) : null}
        {otherAssigmentsManagement ? (
          <div className=" text-gray-700">
            <span className="text-primary font-bold">Other assignments: </span>
            <div
              dangerouslySetInnerHTML={{ __html: otherAssigmentsManagement }}
              className="wp-content"
            />
          </div>
        ) : null}
        {holdingManagement ? (
          <p>
            <span className="text-primary font-bold inline">Holdings:</span>{" "}
            <span className="inline">{holdingManagement}</span>
          </p>
        ) : null}
      </div>
    </div>
  )
}
